const footer = {
  name: "Footer",
  props: {
    logo:
      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/logo/footer_logo.svg",
    logoAltText:
      "Socialpoint animated logo. It swirls fading out and comes back to appear and stay at the same state.",
    contact: {
      address: "Carrer de Pamplona 101",
      city: "08018 Barcelona",
      phone: "T. +34 93 181 40 98",
    },
    social: [
      {
        name: "LinkedIn",
        icon:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/linkedin.svg",
        url: "https://www.linkedin.com/company/social-point",
      },
      {
        name: "Instagram",
        icon:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/instagram.svg",
        url: "https://www.instagram.com/socialpoint",
      },
      {
        name: "Youtube",
        icon:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/youtube.svg",
        url: "https://www.youtube.com/user/SocialPointChannel",
      },
      {
        name: "Twitter",
        icon:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/twitter.svg",
        url: "https://twitter.com/socialpoint",
      },
      {
        name: "Facebook",
        icon:
          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/facebook.svg",
        url: "https://www.facebook.com/socialpoint/",
      },
    ],
    links: [
      {
        text: "Terms of Service",
        hrefTo: "https://legal.socialpoint.es/tos",
      },
      {
        text: "Cookie Policy",
        hrefTo: "https://legal.socialpoint.es/cookiepolicy",
      },
      {
        text: "Privacy Policy",
        hrefTo: "https://legal.socialpoint.es/privacy?v2.0",
      },
      {
        text: "Do Not Sell Or Share My Personal Information",
        hrefTo: "https://legal.socialpoint.es/ccpa",
      },
    ],
  },
};
export default footer;
